.project-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: aliceblue;
  z-index: 1001;
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  align-items: center;  
}

.project {
  margin-top: 28px;
  width: 90%;
  height: 35%;
  display: flex;
  flex-direction: row;
  background-color: rgba(26, 86, 197, 0.15);
  border-radius: 12px;
  align-items: center;
  box-shadow: 4px 4px 20px rgba(82, 122, 197, 0.75);
}

.project.mobile {
  flex-direction: column;
  border: 0;
  margin: 0;
}

.project-header {
  width: 100%;
  background-color: rgba(26, 86, 197, 0.75);
  height: 80px;
  display: flex; 
  align-items: center; /* This will center items vertically */
  justify-content: center; 
}

.project-name {
  color: rgba(255, 255, 255, 1.0);
  font-size: 1.5rem;
}

.close-icon{
  position: absolute;
  left: 10px;
  color: rgba(3, 28, 77, 0.75);
  font-size: 14px;
  font-weight: bold;
  border: solid 2px  rgba(26, 86, 197, 0.75);
  border-radius: 20px ;
  width: 20px;
}

.close-icon:hover{
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.75);;
  border: none;
 }

 .video-container  iframe {
  width: 60vw;
  height: calc(60vw / 1.5666666666666667);
  border-radius: 24px;
  scroll-behavior: none;
  margin: 12px;
}

.video-container.mobile  iframe {
  width: 95vw;
}

.project-description-container {
  width: 40vw;
  height: 90%;
  overflow: auto;
  margin: 12px 20px;
  font-size: 0.9rem;
  flex-grow: 1;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon {
  margin: 1rem;
}

.icons:hover {
  fill: rgba(26, 86, 197, 0.75);
}

.project .project-header-container span,
.project .project-description-container span {
  font-size: 125%;
  padding-right: 8px;
  font-weight: bold;
  color: rgba(82, 122, 197, 1.0);
}

.project-description-container::-webkit-scrollbar {
  display: none;
}


@-moz-document url-prefix() {
  .project-description-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@media screen and (max-width: 786px) {
  .project-description-container {
    width: auto;
  }

  .project {
    width: 100vw;
    height: 90vh;
  }
  
  .video-container {
    margin: 10px;
  }

  .video-container  iframe {
    height: 240px;
  }

  .button-container {
    right: 20px;
  }
}