.Card {
    width: 280px;
    height: 280px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: none;
    background: #ffffff;
    transform-origin: center center;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    color: black;
    box-shadow: 4px 4px 20px rgba(82, 122, 197, 0.75);
    border: 2px solid rgba(82, 122, 197, 1);
}

.Card h2,
.Card .LearnMore {
    transition: color 0.4s ease-in-out;
}

.Card img {
    transform-origin: center;
    transition: transform 0.4s ease-in-out;
}

.Card:hover h2,
.Card:focus h2 {
    color: rgba(82, 122, 197, 0.75);
}

.Card:hover .LearnMore,
.Card:focus .LearnMore {
    color: rgba(82, 122, 197, 1);
    font-weight: bold;
    cursor: pointer;
}

.Card:hover img,
.Card:focus img {
    transform: scale(1.1);
}

.CardBody {
    position: relative;
    height: 140px;
    padding: 24px;
    z-index: 3;
}

.CardDiv {
    position: relative;
    margin-top: -280px;
    width: 280px;
    height: 280px;
    background: #ffffff;
    border-radius: 140px;
    z-index: 2;
}

.CardImg {
    position: relative;
    margin-top: -60px;
    z-index: 1;
}

.Card h2 {
    text-align: center;
    margin: 0 0 16px 0;
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    transition: color 0.3s ease-out;
}

.Card p {
    text-align: center;
    margin: 0 0 16px 0;
    font-size: 1.2rem;
}

.LearnMore {
    text-align: center;
    font-size: 1.2rem;
}

.ComingSoon {
    width: 128px;
    font-size: 16px;
    padding: 4px;
    position: absolute;
    right: -24px;
    top: 16px;
    text-align: center;
    border-radius: 22px;
    transform: rotate(35deg);
    background-color: #ff9800;
    color: white;
}

img {
    max-width: 100%;
}

figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}