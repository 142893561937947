.svg-container{
    margin: 0;
    padding: 0;
    cursor: pointer;
    height: 8vh!important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .svg-container text {
    fill: rgba(0, 0, 0, 0.6);
  }
  
  /* .svg-container:hover text {
    fill: rgba(82, 122, 197, 0.75);
    filter: invert(35%);
  } */

#M, #i, #k, #a, #K_2, #u, #n, #t, #o {
    transform: translateY(2px);
    animation-name: wiggleWiggle;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }
  
  #M { animation-delay: 0s; }
  #i { animation-delay: 0.1s; }
  #k { animation-delay: 0.15s; }
  #s { animation-delay: 0.2s; }
  #K_2 { animation-delay: 0.25s; }
  #u { animation-delay: 0.3s; }
  #n { animation-delay: 0.35s; }
  #t { animation-delay: 0.4s; }
  #o { animation-delay: 0.45s; }
  
  @keyframes wiggleWiggle {
    20%, 100% {
      transform: translate(0, 4px);
    }
    0% {
      transform: translate(0, 0px);
    }
    10% {
      transform: translate(0, 4px);
    }
  }

  @media screen and (max-width: 786px) {
    .svg-container{
      width: 15vw!important;
      height: 10vh!important;
    }
  }