.AboutMeContainer {
  margin-top: 10%;
}

.AboutMeContainer h2{ 
  font-size: 2.2vw;
  justify-content: flex-start;
}

.AboutMeContainer span {
  font-size: 1.2rem;
  line-height: 1.4;
}

.AboutMeContainer span div {
  display: block;
}

@media screen and (max-width: 786px) {
  .AboutMeContainer span{
    margin: 1rem 1rem 1rem 2rem;
  }

  .AboutMeContainer h2 {
    font-size: 20px;
  }
}