.DescriptionContainer {
  margin-top: 10%;
}

.DescriptionContainer h2 {
  font-size: 2.2vw;
  justify-content: flex-start;
}

.DescriptionContainer span{
  font-size: 1.2rem;
  line-height: 1.4;
}

.DescriptionContainer span a {
  display: block;
}

.ProjectGrid {
  margin: 1rem;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 10px;
  grid-auto-rows: 320px;
}

@-moz-document url-prefix() {
  .StarsContainer {
    scrollbar-width: none;
  }
}

@media screen and (max-width: 786px) {
  .DescriptionContainer h2 {
    font-size: 20px;
  }

  .DescriptionContainer span{
    margin: 1rem 1rem 1rem 2rem;
  }

  .ProjectGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}