@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.disable-scroll {
  overflow: hidden;
}

.container {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.website-container {
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: 1024px;
}

.website::-webkit-scrollbar {
  display: none;
  width: 0;
}

.header {
  position: fixed;
  top: 0;
  width: 1024px;
  height: 51.93333333vmin;
  background: url('../assets/background.webp')  no-repeat;
  opacity: 0.8;
  background-size: cover;
}

.site-main {
  margin-top: 51.93333333vmin;
  background: #e3eaf8;
  position: relative;
  border: 1px solid #e3eaf8;
  font-size: 1.2rem;
  padding: 0 8% 0 8%;
  justify-content: center;
  line-height: 1.6;
}

.header.mobile {
  height: 91.93333333vmax;
}
.site-main.mobile {
  margin-top: 91.93333333vmin;
}

.header-title {
  position: absolute;
  bottom: 0.133vh;
  right: 0.133vw;
  width: 39.1333%; /* Adjust this value as needed */
  height: 29.222%; 
}

.header-title span {
  text-shadow: 1px 1px 1px #ffffff, 3px 3px 5px rgba(82, 122, 197, 1);
  font-size: clamp(2.133vw, 3.133vh, 32px);
  line-height: 1.2;
}

@media (max-width: 1024px) {
  .header-title{
    bottom: 5.133vh;
    right: 11.133vw;
  }

  .header-title.mobile{
    bottom: 31.133vh;
    left: 39.133vw;
  }
} 