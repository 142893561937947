.social {
  display: grid;
  margin-left: 40px;
  grid-template-columns: 40px 40px;
  height: 8vh!important;
}

.social a {
  font-size: 20px;
  justify-self: center;
  align-self: center;
}

@media screen and (max-width: 786px) {
  .svg-container{
    width: 10vh!important;
  }
  .social {
    margin: 0.2rem;
    grid-template-columns: 1fr 1fr;
  }
}