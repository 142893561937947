@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Home {
  margin-top: 10%;
  margin-bottom: 10%;
}

.Home  span {
  font-weight: bold;
  font-size: 120%;
  padding-right: 8px;
  color: rgba(82, 122, 197, 1.0);
}

.Home ul {
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: flex-start;
}

.Home li {
  text-align: left;
  -webkit-filter: invert(25%) contrast(200%);
          filter: invert(25%) contrast(200%);
}

.Home p, h3 {
  display: inline;
  /* color: rgba(255, 255, 255, 1.0); */
  mix-blend-mode: difference;
}

.Title {
  -webkit-animation: moving 5s 1;
  animation: moving 5s 1;
}

.Para1 {
  -webkit-animation: ping1 5s 1;
  animation: ping1 5s 1;
}

.Para2 {
  -webkit-animation: ping2 5s 1;
  animation: ping2 5s 1;
}

@-webkit-keyframes moving {
  from {-webkit-transform: translateY(-100vh);transform: translateY(-100vh);}
  to {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes moving {
  from {-webkit-transform: translateY(-100vh);transform: translateY(-100vh);}
  to {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@-webkit-keyframes ping1 {
from {-webkit-transform: translateX(-100vw);transform: translateX(-100vw);}
to {-webkit-transform: translateX(0px);transform: translateX(0px);}
}

@keyframes ping1 {
from {-webkit-transform: translateX(-100vw);transform: translateX(-100vw);}
to {-webkit-transform: translateX(0px);transform: translateX(0px);}
}

@-webkit-keyframes ping2 {
from {-webkit-transform: translateX(100vw);transform: translateX(100vw);}
to {-webkit-transform: translateX(0px);transform: translateX(0px);}
}

@keyframes ping2 {
from {-webkit-transform: translateX(100vw);transform: translateX(100vw);}
to {-webkit-transform: translateX(0px);transform: translateX(0px);}
}
.project-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: aliceblue;
  z-index: 1001;
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  align-items: center;  
}

.project {
  margin-top: 28px;
  width: 90%;
  height: 35%;
  display: flex;
  flex-direction: row;
  background-color: rgba(26, 86, 197, 0.15);
  border-radius: 12px;
  align-items: center;
  box-shadow: 4px 4px 20px rgba(82, 122, 197, 0.75);
}

.project.mobile {
  flex-direction: column;
  border: 0;
  margin: 0;
}

.project-header {
  width: 100%;
  background-color: rgba(26, 86, 197, 0.75);
  height: 80px;
  display: flex; 
  align-items: center; /* This will center items vertically */
  justify-content: center; 
}

.project-name {
  color: rgba(255, 255, 255, 1.0);
  font-size: 1.5rem;
}

.close-icon{
  position: absolute;
  left: 10px;
  color: rgba(3, 28, 77, 0.75);
  font-size: 14px;
  font-weight: bold;
  border: solid 2px  rgba(26, 86, 197, 0.75);
  border-radius: 20px ;
  width: 20px;
}

.close-icon:hover{
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.75);;
  border: none;
 }

 .video-container  iframe {
  width: 60vw;
  height: calc(60vw / 1.5666666666666667);
  border-radius: 24px;
  scroll-behavior: none;
  margin: 12px;
}

.video-container.mobile  iframe {
  width: 95vw;
}

.project-description-container {
  width: 40vw;
  height: 90%;
  overflow: auto;
  margin: 12px 20px;
  font-size: 0.9rem;
  flex-grow: 1;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon {
  margin: 1rem;
}

.icons:hover {
  fill: rgba(26, 86, 197, 0.75);
}

.project .project-header-container span,
.project .project-description-container span {
  font-size: 125%;
  padding-right: 8px;
  font-weight: bold;
  color: rgba(82, 122, 197, 1.0);
}

.project-description-container::-webkit-scrollbar {
  display: none;
}


@-moz-document url-prefix() {
  .project-description-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@media screen and (max-width: 786px) {
  .project-description-container {
    width: auto;
  }

  .project {
    width: 100vw;
    height: 90vh;
  }
  
  .video-container {
    margin: 10px;
  }

  .video-container  iframe {
    height: 240px;
  }

  .button-container {
    right: 20px;
  }
}
.Card {
    width: 280px;
    height: 280px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: none;
    background: #ffffff;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    color: black;
    box-shadow: 4px 4px 20px rgba(82, 122, 197, 0.75);
    border: 2px solid rgba(82, 122, 197, 1);
}

.Card h2,
.Card .LearnMore {
    transition: color 0.4s ease-in-out;
}

.Card img {
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.Card:hover h2,
.Card:focus h2 {
    color: rgba(82, 122, 197, 0.75);
}

.Card:hover .LearnMore,
.Card:focus .LearnMore {
    color: rgba(82, 122, 197, 1);
    font-weight: bold;
    cursor: pointer;
}

.Card:hover img,
.Card:focus img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.CardBody {
    position: relative;
    height: 140px;
    padding: 24px;
    z-index: 3;
}

.CardDiv {
    position: relative;
    margin-top: -280px;
    width: 280px;
    height: 280px;
    background: #ffffff;
    border-radius: 140px;
    z-index: 2;
}

.CardImg {
    position: relative;
    margin-top: -60px;
    z-index: 1;
}

.Card h2 {
    text-align: center;
    margin: 0 0 16px 0;
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    transition: color 0.3s ease-out;
}

.Card p {
    text-align: center;
    margin: 0 0 16px 0;
    font-size: 1.2rem;
}

.LearnMore {
    text-align: center;
    font-size: 1.2rem;
}

.ComingSoon {
    width: 128px;
    font-size: 16px;
    padding: 4px;
    position: absolute;
    right: -24px;
    top: 16px;
    text-align: center;
    border-radius: 22px;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
    background-color: #ff9800;
    color: white;
}

img {
    max-width: 100%;
}

figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}
.DescriptionContainer {
  margin-top: 10%;
}

.DescriptionContainer h2 {
  font-size: 2.2vw;
  justify-content: flex-start;
}

.DescriptionContainer span{
  font-size: 1.2rem;
  line-height: 1.4;
}

.DescriptionContainer span a {
  display: block;
}

.ProjectGrid {
  margin: 1rem;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  grid-auto-rows: 320px;
}

@-moz-document url-prefix() {
  .StarsContainer {
    scrollbar-width: none;
  }
}

@media screen and (max-width: 786px) {
  .DescriptionContainer h2 {
    font-size: 20px;
  }

  .DescriptionContainer span{
    margin: 1rem 1rem 1rem 2rem;
  }

  .ProjectGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.AboutMeContainer {
  margin-top: 10%;
}

.AboutMeContainer h2{ 
  font-size: 2.2vw;
  justify-content: flex-start;
}

.AboutMeContainer span {
  font-size: 1.2rem;
  line-height: 1.4;
}

.AboutMeContainer span div {
  display: block;
}

@media screen and (max-width: 786px) {
  .AboutMeContainer span{
    margin: 1rem 1rem 1rem 2rem;
  }

  .AboutMeContainer h2 {
    font-size: 20px;
  }
}
.social {
  display: grid;
  margin-left: 40px;
  grid-template-columns: 40px 40px;
  height: 8vh!important;
}

.social a {
  font-size: 20px;
  justify-self: center;
  align-self: center;
}

@media screen and (max-width: 786px) {
  .svg-container{
    width: 10vh!important;
  }
  .social {
    margin: 0.2rem;
    grid-template-columns: 1fr 1fr;
  }
}

.Pages {
    font-weight: 600;
    height: 8vh!important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.Pages ul {
  margin: 0;
  padding: 0;
  align-items: center;
}

.Pages ul li {
  margin: 0;
  padding: 0;
}

.Pages ul li a:hover {
  background-color: transparent;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  font-style: italic;
  color: rgb(12, 76, 194);
}

.Pages .LinkActive {
  color: rgb(0, 8, 255);
  font-weight: 800;
}

@media screen and (max-width: 786px) {
  .Pages{
    width: 40vh!important;
  }
}
.svg-container{
    margin: 0;
    padding: 0;
    cursor: pointer;
    height: 8vh!important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .svg-container text {
    fill: rgba(0, 0, 0, 0.6);
  }
  
  /* .svg-container:hover text {
    fill: rgba(82, 122, 197, 0.75);
    filter: invert(35%);
  } */

#M, #i, #k, #a, #K_2, #u, #n, #t, #o {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
    -webkit-animation-name: wiggleWiggle;
            animation-name: wiggleWiggle;
    -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  #M { -webkit-animation-delay: 0s; animation-delay: 0s; }
  #i { -webkit-animation-delay: 0.1s; animation-delay: 0.1s; }
  #k { -webkit-animation-delay: 0.15s; animation-delay: 0.15s; }
  #s { -webkit-animation-delay: 0.2s; animation-delay: 0.2s; }
  #K_2 { -webkit-animation-delay: 0.25s; animation-delay: 0.25s; }
  #u { -webkit-animation-delay: 0.3s; animation-delay: 0.3s; }
  #n { -webkit-animation-delay: 0.35s; animation-delay: 0.35s; }
  #t { -webkit-animation-delay: 0.4s; animation-delay: 0.4s; }
  #o { -webkit-animation-delay: 0.45s; animation-delay: 0.45s; }
  
  @-webkit-keyframes wiggleWiggle {
    20%, 100% {
      -webkit-transform: translate(0, 4px);
              transform: translate(0, 4px);
    }
    0% {
      -webkit-transform: translate(0, 0px);
              transform: translate(0, 0px);
    }
    10% {
      -webkit-transform: translate(0, 4px);
              transform: translate(0, 4px);
    }
  }
  
  @keyframes wiggleWiggle {
    20%, 100% {
      -webkit-transform: translate(0, 4px);
              transform: translate(0, 4px);
    }
    0% {
      -webkit-transform: translate(0, 0px);
              transform: translate(0, 0px);
    }
    10% {
      -webkit-transform: translate(0, 4px);
              transform: translate(0, 4px);
    }
  }

  @media screen and (max-width: 786px) {
    .svg-container{
      width: 15vw!important;
      height: 10vh!important;
    }
  }
.menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 8vh!important;
}

.menu-container {
  width: 1024px;
  display: grid;
  grid-template-columns: 35% 50% 15%;
  grid-auto-flow: row dense;
  grid-template-areas: "logo pages social"
}

.item-a {
  grid-area: logo;
  justify-self: start;
}
.item-b {
  grid-area: pages;
  justify-self: start;
}
.item-c {
  grid-area: social;
  justify-self: start;
}

.menu ul {
  display:flex;
  list-style: none;
  padding: 0;
}

.menu li {
  margin-bottom: 20px;
}

.menu li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  -webkit-filter: invert(35%);
          filter: invert(35%);
}

.menu li a:hover {
  background: rgba(212, 97, 55, 1.0);
}

@supports (-moz-backdrop-filter: none) or ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .menu {
    backdrop-filter: blur(4px) saturate(188%);
    -webkit-backdrop-filter: blur(4px) saturate(188%);
    -moz-backdrop-filter: blur(4px) saturate(188%);
    -ms-backdrop-filter: blur(4px) saturate(188%);
    -o-backdrop-filter: blur(4px) saturate(188%);
  }
}

@media screen and (max-width: 1024px) {
  .menu-container {
    width: 100vw;
  }
  .menu-container {
    grid-template-columns: 35% 45% 20%;
  }
}

@media screen and (max-width: 586px) {
  .menu-container {
    grid-template-columns: 35% 45% 20%;
  }
}
.disable-scroll {
  overflow: hidden;
}

.container {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.website-container {
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: 1024px;
}

.website::-webkit-scrollbar {
  display: none;
  width: 0;
}

.header {
  position: fixed;
  top: 0;
  width: 1024px;
  height: 51.93333333vmin;
  background: url(/static/media/background.573fc7f6.webp)  no-repeat;
  opacity: 0.8;
  background-size: cover;
}

.site-main {
  margin-top: 51.93333333vmin;
  background: #e3eaf8;
  position: relative;
  border: 1px solid #e3eaf8;
  font-size: 1.2rem;
  padding: 0 8% 0 8%;
  justify-content: center;
  line-height: 1.6;
}

.header.mobile {
  height: 91.93333333vmax;
}
.site-main.mobile {
  margin-top: 91.93333333vmin;
}

.header-title {
  position: absolute;
  bottom: 0.133vh;
  right: 0.133vw;
  width: 39.1333%; /* Adjust this value as needed */
  height: 29.222%; 
}

.header-title span {
  text-shadow: 1px 1px 1px #ffffff, 3px 3px 5px rgba(82, 122, 197, 1);
  font-size: clamp(2.133vw, 3.133vh, 32px);
  line-height: 1.2;
}

@media (max-width: 1024px) {
  .header-title{
    bottom: 5.133vh;
    right: 11.133vw;
  }

  .header-title.mobile{
    bottom: 31.133vh;
    left: 39.133vw;
  }
} 
