.menu {
  position: sticky;
  top: 0;
  height: 8vh!important;
}

.menu-container {
  width: 1024px;
  display: grid;
  grid-template-columns: 35% 50% 15%;
  grid-auto-flow: row dense;
  grid-template-areas: "logo pages social"
}

.item-a {
  grid-area: logo;
  justify-self: start;
}
.item-b {
  grid-area: pages;
  justify-self: start;
}
.item-c {
  grid-area: social;
  justify-self: start;
}

.menu ul {
  display:flex;
  list-style: none;
  padding: 0;
}

.menu li {
  margin-bottom: 20px;
}

.menu li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  filter: invert(35%);
}

.menu li a:hover {
  background: rgba(212, 97, 55, 1.0);
}

@supports (-ms-backdrop-filter: none) or  (-moz-backdrop-filter: none) or (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .menu {
    backdrop-filter: blur(4px) saturate(188%);
    -webkit-backdrop-filter: blur(4px) saturate(188%);
    -moz-backdrop-filter: blur(4px) saturate(188%);
    -ms-backdrop-filter: blur(4px) saturate(188%);
    -o-backdrop-filter: blur(4px) saturate(188%);
  }
}

@media screen and (max-width: 1024px) {
  .menu-container {
    width: 100vw;
  }
  .menu-container {
    grid-template-columns: 35% 45% 20%;
  }
}

@media screen and (max-width: 586px) {
  .menu-container {
    grid-template-columns: 35% 45% 20%;
  }
}