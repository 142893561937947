
.Pages {
    font-weight: 600;
    height: 8vh!important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.Pages ul {
  margin: 0;
  padding: 0;
  align-items: center;
}

.Pages ul li {
  margin: 0;
  padding: 0;
}

.Pages ul li a:hover {
  background-color: transparent;
  transform: scale(1.1);
  font-style: italic;
  color: rgb(12, 76, 194);
}

.Pages .LinkActive {
  color: rgb(0, 8, 255);
  font-weight: 800;
}

@media screen and (max-width: 786px) {
  .Pages{
    width: 40vh!important;
  }
}