.Home {
  margin-top: 10%;
  margin-bottom: 10%;
}

.Home  span {
  font-weight: bold;
  font-size: 120%;
  padding-right: 8px;
  color: rgba(82, 122, 197, 1.0);
}

.Home ul {
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: flex-start;
}

.Home li {
  text-align: left;
  filter: invert(25%) contrast(200%);
}

.Home p, h3 {
  display: inline;
  /* color: rgba(255, 255, 255, 1.0); */
  mix-blend-mode: difference;
}

.Title {
  -webkit-animation: moving 5s 1;
  -moz-animation: moving 5s 1;
  animation: moving 5s 1;
}

.Para1 {
  -webkit-animation: ping1 5s 1;
  -moz-animation: ping1 5s 1;
  animation: ping1 5s 1;
}

.Para2 {
  -webkit-animation: ping2 5s 1;
  -moz-animation: ping2 5s 1;
  animation: ping2 5s 1;
}

@keyframes moving {
  from {transform: translateY(-100vh);}
  to {transform: translateY(0px);}
}

@keyframes ping1 {
from {transform: translateX(-100vw);}
to {transform: translateX(0px);}
}

@keyframes ping2 {
from {transform: translateX(100vw);}
to {transform: translateX(0px);}
}